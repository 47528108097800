import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, colors, radius } from 'theme'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import Link from 'components/Link'
import LocalImage from 'components/LocalImage'
import company from 'static/svg/company.svg'
import badgeSmall from 'static/svg/badge_small_tool.svg'

const StyledFlex = styled(Flex)`
  padding: ${space.l} ${space.l} 0 ${space.l};
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 238px;
  background-color: ${colors.white};
  border-radius: ${radius.m};
  box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.1);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 30px 40px rgba(39, 40, 49, 0.1);
  }
`

const StyledImage = styled.img`
  width: 92px;
  height: 92px;
  object-fit: contain;
`

const StyledLink = styled(Link)`
  margin: ${space.l} ${space.s} ${space.l} ${space.s};
  width: 250px;
`

const FeaturedToolsCard = ({ picture_url, name, location, profile_link }) => {
  return (
    <StyledLink href={profile_link}>
      <StyledFlex>
        <Box mb="m" width="92px" height="92px" position="relative">
          <StyledImage src={picture_url} alt={name} />
        </Box>
        <Text mt="s" mb="s">
          {name}
        </Text>
        <Text mt="xxs" fontSize="s" color="secondaryText">
          {location}
        </Text>
      </StyledFlex>
    </StyledLink>
  )
}

FeaturedToolsCard.defaultProps = {
  picture_url: company,
  location: '',
  badge_verified_date: null,
}

FeaturedToolsCard.propTypes = {
  picture_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  profile_link: PropTypes.string.isRequired,
  badge_verified_date: PropTypes.string,
}

export default FeaturedToolsCard
