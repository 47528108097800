import React from 'react'
import styled from 'styled-components'
import { colors, radius } from 'theme'
import { Box, Flex, Grid } from 'components/Layout'
import { Text, H1, H2} from 'components/Typography'
import Button from 'components/Button'
import ExternalLink from 'components/Link'
import AgenciesCounter from 'components/AgenciesCounter'
import bgImageWithDots from 'static/images/home/bg_home.svg'
import bgImage from 'static/images/home/bg_home_background.svg'
import Container from '../../components/Container'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
`

const StyledButtonPrimary = styled(Button.Primary)`
  border-radius: ${radius.xl};
  &:hover {
    p {
      color: ${colors.primary};
    }
    background-color: ${colors.white};
  }
`

const StyledButtonReversed = styled(Button.Reversed)`
  border-radius: ${radius.xl};
  &:hover {
    p {
      color: ${colors.white};
    }
    background-color: ${colors.primary};
  }
`

const Header = () => (
  <Wrapper height={{ mobile: '600px', tablet: '650px', desktop: '750px' }}>
    <BgImg src={bgImage} />
    <Container position="relative" maxWidth="1600px" width="100%" height="100%">
      <BgImg src={bgImageWithDots} />
      <Flex flexDirection="column" alignItems="center">
        <Box mt="xl" mb="s" pt="l" maxWidth="655px">
          <H1 fontSize="xxxxl" color="#212529" fontWeight="extraBold">
            Discover The Perfect Marketing Agency
          </H1>
        </Box>
        <Box my="m" maxWidth="500px">
          <H2 color="#495057" fontSize="l" fontWeight="normal" opacity="0.8">
            Search and connect with <AgenciesCounter as="span" color="primary" fontSize="l" /> verified digital
            marketing agencies. Start free today!
          </H2>
        </Box>
        <Grid mt="s" gridTemplateColumns="repeat(2, auto)" gridGap="l">
          <StyledButtonPrimary as={ExternalLink} href="/find-agency">
            <Text fontWeight="medium" px="xs">
              Find a marketing agency
            </Text>
          </StyledButtonPrimary>
          <StyledButtonReversed as={ExternalLink} href="/search">
            <Text fontWeight="medium" px="xs">
              Browse agencies
            </Text>
          </StyledButtonReversed>
        </Grid>
      </Flex>
    </Container>
  </Wrapper>
)

export default Header
