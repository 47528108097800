import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import App from 'App'
import { COLOR_CONSTANTS, radius } from 'theme'
import { AGENCY_VISTA_REVIEW } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { H1, H2, H3, Text } from 'components/Typography'
import Button from 'components/Button'
import NewsletterSubScription from 'components/NewsletterSubScription'
import Footer from 'components/Footer'
import BlogItem from 'components/BlogItem'
import LocalImage from 'components/LocalImage'
import reviewImage from 'static/images/home/review_image.svg'
import sellingMarketingServices from 'static/images/home/selling_marketing_services.svg'
import quoteImage from 'static/images/home/quote.svg'
import lookingForAMarketingAgencyImage from 'static/images/home/looking_for_a_marketing_agency_image.svg'
import lookingForAMarketingAgencyBackground from 'static/images/home/looking_for_a_marketing_agency_background.svg'
import sellingSoftwareBackground from 'static/images/home/selling_software_background.svg'
import PopularAgenciesCard from './PopularAgenciesCard'
import FeaturedToolsCard from './FeaturedToolsCard'
import AgencySignUpCard from './AgencySignUpCard'
import Header from './Header'

const StyledImage = styled.img`
  pointer-events: none;
  max-width: 100%;
  object-fit: contain;
  z-index: 1;
`

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
`

const BgImgReview = styled(BgImg)`
  top: -110px;
  left: -280px;
  object-fit: fill;
  z-index: 0;
`

const BgImgTools = styled(BgImg)`
  top: 65px;
  object-fit: cover;
  z-index: -1;
`

const BgImgMarketing = styled(BgImg)`
  left: -280px;
  object-fit: fill;
  z-index: 0;
`

const StyledButton = styled(Button.Primary)`
  border-radius: ${radius.xl};
`

const query = graphql`
  {
    allWordpressPost(sort: { fields: [date], order: DESC }, limit: 4) {
      edges {
        node {
          id
          path
          title
          excerpt
          date
          modified
          slug
          author {
            name
          }
          categories {
            slug
            name
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const Home = ({ pageContext: { agencies = [], tools = [], events = [] } }) => {
  let popularAgencies = [...agencies]
  if (popularAgencies.length > 5) {
    popularAgencies = agencies.slice(0, 5)
  }

  let featuredTools = [...tools]
  if (featuredTools.length > 3) {
    featuredTools = tools.slice(0, 3)
  }

  return (
    <StaticQuery
      query={query}
      render={({ allWordpressPost: { edges } }) => {
        const articles = edges.map(({ node }) => node)
        return (
          <App fullHeader>
            <SEO title="Discover The Perfect Marketing Agency for your Business" />
            <Header />

            <Container maxWidth="1080px">
              <Box mt="xxl">
                <Box>
                  <Flex
                    flexDirection={['column', 'row']}
                    justifyContent={['center', 'space-between']}
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Flex order={[1, 2]} flexDirection="column" maxWidth="400px">
                      <H1 fontWeight="bold">Selling marketing services?</H1>
                      <Box maxWidth="380px">
                        <Text mt="l" mb="s" color="ternaryText" fontSize="xl" opacity={0.8} lineHeight="xl">
                          Your future customers are searching for marketing agencies on Agency Vista. Make sure they can
                          find you. Start free today!
                        </Text>
                      </Box>
                      <Box mt="l" as="a" href="/signup/agency">
                        <StyledButton>
                          <Text fontSize="s" px="xs">
                            Claim your agency profile
                          </Text>
                        </StyledButton>
                      </Box>
                    </Flex>
                    <Flex
                      width="100%"
                      maxWidth="500px"
                      order={[2, 1]}
                      mt={['l', '0']}
                      justifyContent="center"
                      position="relative"
                    >
                      <StyledImage
                        src={sellingMarketingServices}
                        alt="Selling marketing services?"
                        width="100%"
                        height="100%"
                      />
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </Container>
            {/* <Sponsors /> */}
            <Flex
              py="xl"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="550px"
              position="relative"
              backgroundColor="#212529"
            >
              <BgImgReview src={lookingForAMarketingAgencyBackground} />
              <Box px="m" maxWidth="1080px" width="100%" zIndex="1" height="100%">
                <Flex
                  flexDirection={['column', 'row']}
                  justifyContent={['center', 'space-between']}
                  alignItems="center"
                  flexWrap={['nowrap', 'wrap']}
                  width="100%"
                  height="100%"
                >
                  <Flex order={[1, 2]} flexDirection="column" maxWidth="378px">
                    <StyledImage src={reviewImage} alt={AGENCY_VISTA_REVIEW.title} width="110px" height="160px" />
                    <Text mt="m" color={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL} opacity={0.8} fontSize="m">
                      {AGENCY_VISTA_REVIEW.author.name}
                    </Text>
                    <Text color={COLOR_CONSTANTS.COSMIC_ENERGY}>{AGENCY_VISTA_REVIEW.author.position}</Text>
                  </Flex>
                  <Flex
                    width="100%"
                    maxWidth="665px"
                    order={[2, 1]}
                    mt={['l', '0']}
                    justifyContent="center"
                    position="relative"
                  >
                    <Box mr="m">
                      <LocalImage source={quoteImage} width="32px" height="40px" />
                    </Box>
                    <Text color={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL} fontSize="xxxl" lineHeight="l">
                      {AGENCY_VISTA_REVIEW.description}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Container maxWidth="1080px">
              <Box mt="xxl" pb="xxl" position="relative">
                <BgImgMarketing src={lookingForAMarketingAgencyBackground} />
                <Flex
                  flexDirection={['column', 'row']}
                  justifyContent={['center', 'space-between']}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Flex order={[1, 1]} flexDirection="column" maxWidth="430px">
                    <H1 fontWeight="bold">Looking for a marketing agency?</H1>
                    <Box maxWidth="320px">
                      <Text mt="l" mb="s" color="ternaryText" fontSize="xl" opacity={0.8} lineHeight="xl">
                        Search and connect with thousands of verified digital marketing agencies. Start free today!
                      </Text>
                    </Box>
                    <Box mt="l" as="a" href="/find-agency">
                      <StyledButton>
                        <Text fontSize="s" px="xs">
                          Find a marketing agency
                        </Text>
                      </StyledButton>
                    </Box>
                  </Flex>
                  <Flex
                    width="100%"
                    maxWidth="500px"
                    order={[2, 2]}
                    mt={['l', '0']}
                    justifyContent="center"
                    position="relative"
                  >
                    <StyledImage
                      src={lookingForAMarketingAgencyImage}
                      alt="Looking for a marketing agency?"
                      width="100%"
                      height="100%"
                    />
                  </Flex>
                </Flex>
              </Box>
            </Container>
            {popularAgencies.length > 0 && (
              <Fragment>
                <Container maxWidth="1080px">
                  <Box mt="l">
                    <H3>Explore Our Most Popular Verified Agencies</H3>
                  </Box>
                </Container>
                <Flex mt="s" px="l" justifyContent="center" flexWrap="wrap">
                  <Grid mt="s" p="s" gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="s" gridRowGap="xxs">
                    {popularAgencies.map((item) => (
                      <PopularAgenciesCard key={item.agency_gid} {...item} />
                    ))}
                    <AgencySignUpCard title="Do you want to get listed among the leading agencies in the world?" />
                  </Grid>
                </Flex>
              </Fragment>
            )}
            {featuredTools.length > 0 && (
              <Flex
                py="xxl"
                width="100%"
                height="100%"
                position="relative"
                flexDirection="column"
                maxWidth="1280px"
                mx="auto"
              >
                <BgImgTools src={sellingSoftwareBackground} />
                <Box maxWidth="1080px" width="100%" mx="auto" px="m">
                  <Flex flexDirection="column" maxWidth="400px">
                    <H1 fontWeight="bold">Selling software? </H1>
                    <Box maxWidth="320px">
                      <Text mt="l" mb="s" color="ternaryText" fontSize="xl" opacity={0.8} lineHeight="xl">
                        Your future customers are researching their next purchase on{' '}
                        <Text fontSize="xl" as="a" href="/hub" color="primary">
                          Agency Vista Hub
                        </Text>
                        . Make sure they can find you.
                      </Text>
                    </Box>
                    <Box mt="l" as="a" href="/signup/hub">
                      <StyledButton>
                        <Text fontSize="s" px="xs">
                          Claim your profile
                        </Text>
                      </StyledButton>
                    </Box>
                  </Flex>
                </Box>
                <Flex mt="s" px="l" justifyContent="center" flexWrap="wrap">
                  {featuredTools.map((item) => (
                    <FeaturedToolsCard key={item.id} {...item} />
                  ))}
                </Flex>
              </Flex>
            )}
            <Flex px="l" mt="xl" flexDirection="column" alignItems="center">
              <H2 textAlign="center">Latest from our blog</H2>
              <Text fontSize="l" mt="m" color="secondaryText" textAlign="center">
                Learn from the pro's and get insights that actually fuel growth
              </Text>
              <Flex mt="l" flexWrap="wrap" justifyContent="center">
                {articles.slice(0, 4).map((article) => (
                  <Box key={article.id} mx="s" my="l" maxWidth="320px">
                    <BlogItem {...article} showAuthor={false} showTeaser={false} />
                  </Box>
                ))}
              </Flex>
            </Flex>
            <Box px={['m', 0]}>
              <NewsletterSubScription hasBottomBorder={false} type="homepage" />
            </Box>
            <Footer />
          </App>
        )
      }}
    />
  )
}

Home.propTypes = {
  pageContext: PropTypes.shape({
    filterData: PropTypes.object.isRequired,
    agencies: PropTypes.array,
    tools: PropTypes.array,
    events: PropTypes.array,
  }).isRequired,
}

export default Home
