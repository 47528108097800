import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, colors, radius } from 'theme'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import Link from 'components/Link'
import logo from 'static/logo/logo.png'
import Button from 'components/Button'

const StyledFlex = styled(Flex)`
  padding: ${space.m};
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 238px;
  background-color: ${colors.white};
  border-radius: ${radius.m};
  box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.1);
`

const StyledImage = styled.img`
  width: 52px;
  height: 52px;
  object-fit: contain;
`

const AgencySignUpCard = ({ title }) => (
  <Box width="250px" mx="s" my="l">
    <StyledFlex>
      <Box my="m" width="52px" height="38px">
        <StyledImage src={logo} alt="Agency Vista" />
      </Box>
      <Box my="s" width="210px">
        <Text lineHeight="20px" mx="xs" fontSize="m">
          {title}
        </Text>
      </Box>
      <Box mt="m" width="100%">
        <Button.Primary as={Link} href="/signup/agency" width="100%" fontSize="s">
          Sign up today
        </Button.Primary>
      </Box>
    </StyledFlex>
  </Box>
)

AgencySignUpCard.defaultProps = {
  title: PropTypes.string.isRequired,
}

export default AgencySignUpCard
